import React, { useState } from 'react'
import PropTypes from 'prop-types'
import CheckBox from '@acodez/checkbox'
import Profilepreviewsmall from '@acodez/profilepreviewsmall'
import Tooltip from '@acodez/tooltip'
import styles from './styles.module.scss'
import '@acodez/tooltip/dist/index.css'

function createMarkup(val) {
  return { __html: val }
}

const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === 'object' && Object.keys(value).length === 0) ||
  (typeof value === 'string' && value.trim().length === 0)

const dateFormat = (date) => {
  if (date) {
    return `${new Date(date).getDate()}/${new Date(date).getMonth() + 1
      }/${new Date(date).getFullYear()}`
  }
  return null
}

const timeFormat = (time) => {
  if (time) {
    if (`${new Date(time).getHours()}`.length === 1 && `${new Date(time).getMinutes()}`.length === 1) {
      return `0${new Date(time).getHours()}:0${new Date(time).getMinutes()}`;
    } else if (`${new Date(time).getHours()}`.length === 1 && `${new Date(time).getMinutes()}`.length == 2) {
      console.log("SDsd")
      return `0${new Date(time).getHours()}:${new Date(time).getMinutes()}`;
    } else if (`${new Date(time).getHours()}`.length === 2 && `${new Date(time).getMinutes()}`.length == 1) {
      return `${new Date(time).getHours()}:0${new Date(time).getMinutes()}`;
    } else if (`${new Date(time).getHours()}`.length === 2 && `${new Date(time).getMinutes()}`.length == 2) {
      return `${new Date(time).getHours()}:${new Date(time).getMinutes()}`;
    }
  }
  return null
}


const imagePreview = (mediaStorageType, fileName, filePath) => {
  let url;
  if (mediaStorageType === 'FileStore') {
    url = `${process.env.REACT_APP_BASE_URL}${filePath}/${fileName}`;
  } else if (mediaStorageType === 'S3Storage') {
    url = `${process.env.AWS_BASE_URL}${filePath}/${fileName}`;
  } else if (mediaStorageType === 'frontEnd') {
    url = `${process.env.REACT_APP_FRONTEND_BASE_URL}${fileName}`;
  } else if (mediaStorageType === 'mobileServer') {
    url = `${process.env.RECT_APP_MOBILE_SERVER_BASE_URL}${filePath}/${fileName}`;
  }
  return url;
}
const hasPermission = (data, permission) => {
  return permission?.[data?.url]?.filter(v => v.aclKey === data?.aclKey)[0].action === "allow";
}

const getObjectProp = (obj, prop) => {
  if (typeof prop !== 'string') return obj
  if (typeof obj === 'string') return obj
  // if (typeof obj === "undefined") {
  //   return false;
  // }
  if (typeof prop === 'undefined') {
    return false
  }
  const _index = prop?.indexOf('.') || -2
  if (_index > -1) {
    return getObjectProp(
      obj?.[prop?.substring(0, _index)],
      prop?.substr(_index + 1)
    )
  }
  return obj?.[prop]
}

// Status Component
const Status = ({ value }) => {
  const getTagStyle = (val) => {
    const style = { color: '#FFFFFF' }
    if ('active live published posted'.split(' ').includes(val?.toLowerCase())) {
      style.backgroundColor = '#6fcf971a'
      style.color = '#27AE60'
    } else if (
      'suspended draft unpublished pending'
        .split(' ')
        .includes(val?.toLowerCase())
    ) {
      style.backgroundColor = '#eb575717'
      style.color = '#EB5757'
    } else if (
      'accepted completed paid resolved'.split(' ').includes(val?.toLowerCase())
    ) {
      style.backgroundColor = '#22d369'
    } else if ('confirmed  transist'.split(' ').includes(val?.toLowerCase())) {
      style.backgroundColor = '#3366ff'
    } else if (
      'rejected canceled inactive'.split(' ').includes(val?.toLowerCase())
    ) {
      style.backgroundColor = '#e62045'
    } else if ('resubmitted'.split(' ').includes(val?.toLowerCase())) {
      style.backgroundColor = '#7540ee'
    } else if ('counter'.split(' ').includes(val?.toLowerCase())) {
      style.backgroundColor = '#229ff3'
    }
    return style
  }

  return (
    <div style={getTagStyle(value)} className={styles.tagStyles}>
      {value}
    </div>
  )
}

const NoData = () => (
  <svg
    className={styles.empty}
    xmlns='http://www.w3.org/2000/svg'
    width='216'
    height='96.25'
    viewBox='0 0 216 96.25'
  >
    <path
      d='M202,63a7,7,0,1,1,7,7A7,7,0,0,1,202,63Zm-62,7a7.037,7.037,0,0,1-1.5-.161A7.043,7.043,0,0,1,137,70H46a7,7,0,1,1,0-14H7A7,7,0,1,1,7,42H47a7,7,0,0,0,0-14H22a7,7,0,0,1,0-14H62A7,7,0,1,1,62,0h57a7,7,0,0,1,0,14h64a7,7,0,1,1,0,14h22a7,7,0,1,1,0,14H186a7,7,0,1,0,0,14h6a7,7,0,0,1,0,14Z'
      fill='#e9ebf2'
    />
    <path
      d='M143.128.5h0ZM116,.5h0ZM13,.5h0ZM.128.5h0Z'
      transform='translate(40 81)'
      fill='none'
      stroke='#75a4fe'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit='10'
      strokeWidth='2.5'
    />
    <path
      d='M73,0V12a1.939,1.939,0,0,1-1.872,2H1.872A1.939,1.939,0,0,1,0,12V0'
      transform='translate(150 16) rotate(180)'
      fill='#e8f0fe'
      stroke='#75a4fe'
      strokeMiterlimit='10'
      strokeWidth='2.5'
    />
    <path
      d='M71.524,79H4.488A4,4,0,0,1,.506,75V3A3.033,3.033,0,0,1,3.564,0H73.515a2,2,0,0,1,1.991,2V75A4,4,0,0,1,71.524,79Z'
      transform='translate(75.5 16)'
      fill='#fff'
      stroke='#75a4fe'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2.5'
    />
    <path
      d='M14,7A7,7,0,0,0,0,7'
      transform='translate(106.006 53) rotate(180)'
      fill='none'
      stroke='#75a4fe'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2.5'
    />
    <path
      d='M14,7A7,7,0,0,0,0,7'
      transform='translate(135.006 53) rotate(180)'
      fill='none'
      stroke='#75a4fe'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2.5'
    />
    <path
      d='M0,1H14.5'
      transform='translate(106.006 64)'
      fill='none'
      stroke='#75a4fe'
      strokeLinecap='round'
      strokeMiterlimit='10'
      strokeWidth='2.5'
    />
  </svg>
)
const Modal = ({
  isModalOpen,
  confirmClass = 'confirm',
  onConfirm,
  onClose,
  permission = true,
  confirmText
}) => {
  const [confirmLabel, setconfirmLabel] = useState('')
  const handleChange = ({ target }) => {
    setconfirmLabel(target.value)
  }
  const handleClose = (e) => {
    e.stopPropagation()
    onClose()
  }
  const handleConfirm = (e) => {
    e.stopPropagation()
    if ((confirmText || 'confirm') === confirmLabel) onConfirm()
  }
  if (isModalOpen)
    return (
      <div className={styles.modalContainer} onClick={handleClose}>
        <div className={styles.modal}>
          <p>Confirm Action</p>
          <p>
            To perform this action enter the confirmation text
            <i>
              <b style={{ marginLeft: 10 }}>{confirmText || ' confirm'}</b>
            </i>
          </p>
          <input
            placeholder={confirmText || 'confirm'}
            type='text'
            onClick={(e) => e.stopPropagation()}
            value={confirmLabel}
            onChange={handleChange}
          />
          <div className={styles.button_area}>
            <button onClick={handleClose} className={styles.cancel}>
              Cancel
            </button>
            <button
              className={styles[confirmClass]}
              disabled={((confirmText || 'confirm' ) !== confirmLabel || !permission)}
              onClick={handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    )
  return null
}

// Table Header Component
const TableHeader = ({
  isCheckable,
  onSort,
  data,
  onAllCheck,
  checked = {},
  headings,
  buttons
}) => {
  return (
    <thead>
      <tr>
        {isCheckable && (
          <th>
            <CheckBox
              name='checkall'
              onClick={onAllCheck}
              label={' '}
              checked={
                parseInt(
                  Object.keys(checked)?.filter(
                    (val) => checked[val] && val !== '*'
                  )?.length
                ) === parseInt(data?.length)
              }
            />
          </th>
        )}
        {headings?.map(({ label, key, sortable = false }) => (
          <th key={key} onClick={sortable ? onSort : null}>
            {label}
          </th>
        ))}

        {!isEmpty(buttons) && <th> </th>}
      </tr>
    </thead>
  )
}

// Table Data Component
const TableData = (props) => {
  const { data, component: Component, style, dataKey, type, currency } = props
  const val = getObjectProp(data, dataKey)
  switch (type) {
    case 'image':
      return (
        <td style={style}>
          <div style={{ width: '50px' }}>
            <img src={imagePreview(data?.mediaStorageType, data?.profileImageName, data?.profileImagePath)} alt={val} style={{ width: '100%' }} />
          </div>
        </td>
      )

    case 'profilepreview':
      return (
        <td style={style}>
          <Profilepreviewsmall
            mediaStorageType={data?.mediaStorageType}
            profileimage={imagePreview(data?.mediaStorageType, data?.profileImageName, data?.profileImagePath)}
            title1={data?.[dataKey?.title]}
            title2={data?.[dataKey?.title2]}
          />
        </td>
      )
    case 'status':
      return (
        <td style={style}>
          <Status value={val} />
        </td>
      )
      case 'time':
        return <td style={style}>{timeFormat(val)}</td>
    case 'date':
      return <td style={style}>{dateFormat(val)}</td>
    case 'custom':
      return (
        <td style={style}>
          <Component rowData={data} value={val} />
        </td>
      )
    case 'html':
      return (
        <td style={style}>
          <p dangerouslySetInnerHTML={createMarkup(val)} />
        </td>
      )
    case 'currency':
      return <td style={style}>{`${currency}${val}`}</td>
      case 'array':
        return  <td style={style}>{val.length}</td>
    default:
      return <td style={style}>{val}</td>
  }
}

// Table Body Component
const TableBody = ({ rows, ...other }) => {
  const getTdLength = () => {
    let length = other.headings.length
    if (other.isCheckable) length += 1
    if (!isEmpty(other.buttons)) length += 1
    return length
  }
  return (
    <tbody>
      {rows?.map((row) => (
        <TableRow key={row.id || row._id} data={row} rows={rows} {...other} />
      ))}
      {isEmpty(rows) && (
        <tr>
          <td colSpan={getTdLength()}>
            <NoData />
          </td>
        </tr>
      )}
    </tbody>
  )
}

// Table Row Component
const TableRow = ({
  data,
  isCheckable,
  checked,
  onCheck,
  headings,
  buttons
}) => {
  return (
    <tr key={data?.id || data?._id}>
      {isCheckable && (
        <td>
          <CheckBox
            name={data?.id || data?._id}
            label={' '}
            checked={checked[data?._id]}
            onClick={() => onCheck(data)}
          />
        </td>
      )}
      {headings?.map(({ key, type, component, style, currency }) => (
        <TableData
          component={component}
          key={data.id || data._id}
          data={data}
          currency={currency}
          style={style}
          dataKey={key}
          type={type}
        />
      ))}
      <TableRowActions buttons={buttons} data={data} />
    </tr>
  )
}

const TableRowActions = ({ buttons, data }) => {
  const [modalOpen, setmodalOpen] = useState(false)
  if (!isEmpty(buttons)) {
    return (
      <td
        style={{
          minWidth: '180px',
          padding: '0px 22px 0px 10px',
          textAlign: 'right'
        }}
      >
        {buttons?.map(
          ({
            action,
            confirmText = 'confirm',
            confirmClass,
            label,
            icon,
            permission = true,
            type,
            items
          }) => {
            switch (type) {
              case 'list':
                return (
                  <span
                    key={label}
                    className={[styles.actionicon, styles.listouter].join(' ')}
                  >
                    <img src={icon} alt={label} />
                    <div className={styles.listitems}>
                      {items?.map(
                        ({
                          item,
                          confirmText = 'confirm',
                          type,
                          onClick,
                          permission = true,
                          label
                        }) => (
                          permission ?
                            <div
                              key={label}
                              className={[styles.listitem].join(' ')}
                              onClick={() => {
                                type === 'modal'
                                  ? setmodalOpen(true)
                                  : onClick(data)
                              }}
                            >
                              <Modal
                                confirmClass={confirmClass}
                                onConfirm={() => onClick(data)}
                                confirmText={confirmText}
                                permission={permission}
                                onClose={(e) => {
                                  setmodalOpen(false)
                                }}
                                isModalOpen={modalOpen}
                              />
                              {label}
                            </div> : null
                        )
                      )}
                    </div>
                  </span>
                )
              case 'modal':
                return (
                  <span
                    key={label}
                    className={[styles.actionicon, !permission && styles.disabled].join(" ")}
                    onClick={() => setmodalOpen(true)}
                  >
                    <Modal
                      onConfirm={() => action(data)}
                      confirmClass={confirmClass}
                      confirmText={confirmText}
                      permission={permission}
                      onClose={() => {
                        setmodalOpen(false)
                      }}
                      isModalOpen={modalOpen}
                    />
                    <img src={icon} alt={label} />
                  </span>
                )
              default:
                return (
                  <span
                    key={label}
                    className={[styles.actionicon, !permission && styles.disabled].join(" ")}
                    onClick={() => action(data)}
                  >
                    <img src={icon} alt={label} />
                  </span>
                )
            }
          }
        )}
      </td>
    )
  } else return null
}

const TableBulkAction = ({
  bulkactions,
  checked,
  grandTotal,
  onUltimateCheck
}) => {
  const checkedCount = Object.keys(checked).filter(
    (val) => checked[val] && val !== '*'
  ).length
  if (!isEmpty(bulkactions)) {
    return (
      <div className={styles.tableactions}>
        <div className={styles.bulksect}>
          <div className={styles.text}>Bulk action:</div>
          {bulkactions?.map(({ icon, action, label }) => (
            <Tooltip text={label} key={label}>
              <span
                className={[
                  styles.bkbtn,
                  checkedCount > 0 ? '' : styles.disabled
                ].join(' ')}
                onClick={checkedCount > 0 ? action : null}
              >
                <img src={icon} alt={label} />
              </span>
            </Tooltip>
          ))}
        </div>

        {/* {checkedCount > 0 && (
          <div className={styles.selectedmsg}>
            <span className={styles.msg}>{`${checkedCount || 0} entries have been selected`}</span>
            <span className={styles.selectall} onClick={onUltimateCheck}>
              {`Click here to select all the ${grandTotal || ''} entries in the list`}
            </span>
          </div>
        )} */}
      </div>
    )
  }
  return null
}

// Table Component
const Table = ({
  headings = [],
  rows = [],
  isCheckable = false,
  onCheck = () => { },
  pageCheckedLabel,
  ultimateCheckedLabel,
  onAllCheck = () => { },
  onUltimateCheck = () => { },
  buttons = [],
  checked = {},
  isUltimatedChecked = false,
  bulkactions = [],
  size,
  onSort,
  backgroundColor,
  tablestyle,
  custom,
  ...otherProps
}) => {
  return (
    <div {...otherProps}>
      <TableBulkAction
        bulkactions={bulkactions}
        checked={checked}
        pageCheckedLabel={pageCheckedLabel}
        onUltimateCheck={onUltimateCheck}
        ultimateCheckedLabel={ultimateCheckedLabel}
      />
      <div className={styles.tableouter}>
        <table
          className={[
            styles.table,
            styles?.[size],
            styles?.[backgroundColor],
            styles?.[tablestyle],
            custom && styles.custom,
            isCheckable && styles.check
          ].join(' ')}
        >
          <TableHeader
            isCheckable={isCheckable}
            data={rows}
            onSort={onSort}
            onAllCheck={onAllCheck}
            isUltimatedChecked={isUltimatedChecked}
            headings={headings}
            checked={checked}
            buttons={buttons}
          />
          <TableBody
            rows={rows}
            isCheckable={isCheckable}
            checked={checked}
            onCheck={onCheck}
            headings={headings}
            buttons={buttons}
          />
        </table>
      </div>
    </div>
  )
}

Table.defaultProps = {
  headings: [],
  rows: [],
  isCheckable: false,
  onCheck: () => { },
  buttons: [],
  checked: {},
  bulkactions: [],
  backgroundColor: '',
  size: '',
  tablestyle: '',
  custom: false,
  isAllChecked: false,
  onAllCheck: false
}

Table.propTypes = {
  headings: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          image: PropTypes.string,
          title: PropTypes.string,
          title2: PropTypes.string
        })
      ]),
      type: PropTypes.oneOf(['profilepreview', 'image']),
      sortable: PropTypes.bool
    })
  ).isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  isCheckable: PropTypes.bool,
  pageCheckedLabel: PropTypes.string,
  ultimateCheckedLabel: PropTypes.string,
  isAllChecked: PropTypes.bool,
  onAllCheck: PropTypes.func,
  onUltimateCheck: PropTypes.func,
  isUltimatedChecked: PropTypes.bool,
  onCheck: PropTypes.func,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      action: PropTypes.func,
      label: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          onClick: PropTypes.func
        })
      )
    })
  ),
  checked: PropTypes.object,
  bulkactions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      action: PropTypes.func,
      label: PropTypes.string
    })
  ),
  backgroundColor: PropTypes.string,
  size: PropTypes.string,
  tablestyle: PropTypes.string,
  custom: PropTypes.bool,
}

export default Table
